<template>
  <v-row
    justify="center"
    align="center"
    class="flex-column"
  >
    <v-col>
      <v-row
        justify="center"
        class="flex-nowrap"
      >
        <v-col
          cols="2"
        >
          <v-menu
            ref="monthPicker"
            v-model="showMonthPicker"
            :close-on-content-click="false"
            :return-value.sync="selectedDate"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormatted"
                outlined
                :prepend-icon="icons.mdiCalendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              locale="en"
              v-model="selectedDate"
              type="month"
              no-title
              scrollable
              @input="$refs.monthPicker.save(selectedDate)"
            >
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="2"
        >
          <v-btn
            id="searchBtn"
            color="info"
            style="text-transform: none;margin-top: 8px;"
            @click="queryMemoCallback"
          >
            <div>Search</div>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col>
      <ag-grid-vue
        class="ag-theme-alpine"
        dom-layout="autoHeight"
        :column-defs="columnDefs"
        :default-col-def="defaultColDef"
        :row-data="rowData"
        :get-row-class="getRowClass"
        :column-types="columnTypes"
        :stop-editing-when-cells-lose-focus="true"
        @first-data-rendered="onFirstDataRendered"
      >
      </ag-grid-vue>
    </v-col>
    <v-spacer></v-spacer>
    <v-col class="text-center">
      <v-btn
        color="success"
        v-if="showflg"
        style="text-transform: none"
        @click="saveMemoCallback"
      >
        <div>Save</div>
      </v-btn>
    </v-col>
    <br>
  </v-row>
</template>
<script>
import { AgGridVue } from 'ag-grid-vue'
import { ref } from '@vue/composition-api'
import { mdiCalendar } from '@mdi/js'
import playRender from './playRender.vue'
import NumericFormat from './NumericFormat.vue'
import moment from 'moment'
import { isEmpty } from '@core/utils'

moment.locale('en')

export default {
  components: {
    AgGridVue,

    /* eslint-disable vue/no-unused-components */
    playRender,
    NumericFormat,
  },

  props: {
    queryMemo: {
      type: Function,
      required: true,
    },
    saveMemo: {
      type: Function,
      required: true,
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.selectedDate)
    },
  },
  methods: {
    formatDate(date) {
      if (!date) return null
      console.log(moment(date).format('MMM/yyyy'))

      return moment(date).format('MMM/yyyy')
    },
    onFirstDataRendered(params) {
      params.api.sizeColumnsToFit()
    },
    helloChild() {
      const searchBtn = document.getElementById('searchBtn')
      if (searchBtn != null) {
        setTimeout(() => {
          searchBtn.click()
        }, 500)
      }
    },
  },
  setup(props) {
    const showMonthPicker = ref(false)

    const selectedDate = ref(new Date().toISOString().substr(0, 7))

    const rowData = ref([])

    const defaultColDef = {
      initialWidth: 100,
      resizable: true,
    }

    const columnTypes = {
      editableColumn: { editable: true, cellEditor: 'agLargeTextCellEditor', cellEditorParams: { maxLength: '300', cols: '50', rows: '6' } },
    }

    const columnDefs = [
      { headerName: 'Date', field: 'day', minWidth: 120, cellStyle: { 'text-align': 'center' } },
      { headerName: 'EventName', minWidth: 300, field: 'name', type: 'editableColumn' },

      {
        headerName: 'Play',
        field: 'play',
        maxWidth: 135,
        cellRendererFramework: 'playRender',
      },
    ]

    const getRowClass = params => {
      if (params.data.day.indexOf('Sat') >= 0) {
        return ['event-table', 'saturday']
      }
      if (params.data.day.indexOf('Sun') >= 0) {
        return ['event-table', 'sunday']
      }

      return undefined
    }

    const queryMemoCallback = async () => {
      rowData.value = await props.queryMemo(new Date(selectedDate.value))
    }

    const saveMemoCallback = () => {
      props.saveMemo(rowData.value)
    }

    const showflg = !isEmpty(sessionStorage.getItem('selectEventCameraName'))

    return {
      showflg,
      NumericFormat,
      playRender,
      showMonthPicker,
      selectedDate,
      defaultColDef,
      columnTypes,
      columnDefs,
      rowData,
      getRowClass,
      queryMemoCallback,
      saveMemoCallback,
      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-alpine.css';
@import '~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin';

.event-table {
  &.saturday {
    background-color: rgb(204, 255, 255) !important;
  }
  &.sunday {
    background-color: rgb(255, 204, 255) !important;
  }
}

.ag-theme-alpine {
  @include ag-theme-alpine(
    (
      font-family: 'Arial sans-serif',
      border-color: black,
      header-column-resize-handle-height: 100%,
      header-column-resize-handle-color: ag-derived(border-color),
      row-border-color: ag-derived(border-color),
      cell-horizontal-border: solid ag-derived(border-color),
      odd-row-background-color: null,
    )
  );
  .ag-ltr {
    .ag-header-cell:last-child > .ag-header-cell-resize {
      width: 0px;
    }
    .ag-row {
      .ag-cell:last-child {
        border-right: none;
      }
    }
    .ag-center-cols-container {
      .ag-row:last-child {
        border-bottom: none;
      }
    }
  }
}
.ag-header-cell-label {
  justify-content: center;
}
</style>
