<template>
  <div style="width:100%">
    <Loading v-show="loading"></Loading>
    <v-card>
      <!-- actions -->
      <v-card-text class="d-flex align-center flex-wrap pb-0">
        <div class="d-flex align-center flex-wrap">
          <v-select
            v-model="selectEventCameraName"
            label="Select Event Place"
            :items="eventPlaceInfo"
            item-text="displayName"
            item-value="eventCameraName"
            outlined
            dense
            hide-details="auto"
            class="mb-6"
            @change="callChildMethod"
          >
          </v-select>
        </div>
        <v-spacer></v-spacer>
      </v-card-text>

      <!-- table -->
       <event-table
          style="width:850px"
          ref="eventTable"
          :query-memo="queryMemo"
          :save-memo="saveMemo"
        >
    </event-table>
    </v-card>
  </div>
</template>
<script>
import eventTable from '@/views/tables/datatable/EventTable.vue'
import store from '@/store'
import eventboxStoreModule from './eventboxStoreModule'
import { ref, onUnmounted } from '@vue/composition-api'
import Loading from '@/views/components/load/Loading.vue'
import { getSessionData, isEmpty } from '@core/utils'

const USER_APP_STORE_MODULE_NAME = 'app-eventbox'
const eventPlaceInfo = ref([])
const selectEventCameraName = ref()

export default {
  components: {
    eventTable,
    Loading,
  },
  created() {
    const siteId = String(getSessionData('data').siteId)
    store
      .dispatch('app-eventbox/fetchEventPlaceInfo', {
        params: {
          siteId,
        },
      })
      .then(response => {
        eventPlaceInfo.value = response.data.result.data.eventPlaceInfoData

        if (!isEmpty(eventPlaceInfo.value)) {
          const eventCameraName = String(eventPlaceInfo.value[0].eventCameraName)

          selectEventCameraName.value = eventCameraName
          sessionStorage.setItem('selectEventCameraName', eventCameraName)
        }
      })
      .catch(error => {
        console.log(error)
        this.$router.push({ name: 'error-login' })
      })
  },
  mounted() {
    this.$dateRangePickerDisplayFlag = false
    this.$selectScDisplayFlag = true
    this.$selectDomesticScDisplayFlag = false
    this.$domesticDatePickerDisplayFlag = false
    this.$datePickerDisplayFlag = false
    this.callChildMethod()
  },
  methods: {
    async queryMemo(date) {
      console.log(date)
      const siteId = String(getSessionData('data').siteId)
      const searchMonth = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}`
      console.log(searchMonth)
      const rowData = ref([])
      this.loading = true
      const eventCameraName = selectEventCameraName.value

      await store
        .dispatch('app-eventbox/fetchEventInfo', {
          params: {
            siteId,
            searchMonth,
            eventCameraName,
          },
        })
        .then(response => {
          rowData.value = response.data.result.data.rowData
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.$router.push({ name: 'error-login' })
        })

      return rowData.value
    },
    saveMemo(array) {
      console.log(array)
      const newArray = []

      // const element = array[0]
      array.forEach(element => {
        const stringDate = element.day.substr(0, element.day.indexOf('('))
        const months = { Jan: '01', Feb: '02', Mar: '03', Apr: '04', May: '05', Jun: '06', Jul: '07', Aug: '08', Sep: '09', Oct: '10', Nov: '11', Dec: '12' }
        const p = stringDate.split('/')
        const dateFormat = `${p[2]}
          -${months[p[1]]}
          -${p[0]}`.replace(/\s+/g, '')
        console.log(dateFormat)
        const obj = {
          eventId: element.eventId,
          day: dateFormat,
          name: element.name,
          eventCameraName: element.eventCameraName,
          imagePath: element.imagePath,
        }
        newArray.push(obj)
      })
      const eventList = JSON.stringify(newArray)
      const siteId = String(getSessionData('data').siteId)

      const eventCameraName = selectEventCameraName.value
      store
        .dispatch('app-eventbox/saveDate', {
          params: {
            siteId,
            eventCameraName,
            eventList,
          },
        })
        .then(response => {
          console.log(response.data.result.data)
          this.$toasted.success('Your data has been successfully saved')
        })
        .catch(error => {
          console.log(error)
          this.$router.push({ name: 'error-login' })
        })
    },
    callChildMethod() {
      this.$refs.eventTable.helloChild()
    },
  },
  setup() {
    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, eventboxStoreModule)
    }
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    return { eventPlaceInfo, selectEventCameraName, loading: false }
  },
}
</script>
