<template>
  <v-btn
    v-if="!isEmpty(this.params.data.imagePath)"
    color="primary"
    style="text-transform: none"
    @click.stop="play()"
  >
    Play
    <v-icon
      dark
      right
    >
      {{ icons.mdiPlayCircleOutline }}
    </v-icon>
  </v-btn>
</template>

<script>
import { mdiPlayCircleOutline } from '@mdi/js'
import { isEmpty } from '@core/utils'

export default {
  name: 'CellRendererActions',
  methods: {
    play() {
      const resolvedRoute = this.$router.resolve({
        name: 'apps-gifPlayer',
      })
      sessionStorage.setItem('imagePath', this.params.data.imagePath)
      window.open(resolvedRoute.href, '_blank')
    },
  },
  setup() {
    return {
      isEmpty,

      // icons
      icons: {
        mdiPlayCircleOutline,
      },
    }
  },
}
</script>

<style>
</style>
